import { ReactSortable } from "react-sortablejs";

const Chapter = ({chapter, setActiveChapter, activeChapter, setActiveLesson, sortLessons, activeLesson, deleteLesson, deleteChapter, addLesson}) => {
  const lessons = chapter.lessons

  return (
    <div className="card chapter">
      <div className={`card-body ${activeChapter?.id == chapter.id ? 'bg-light-info' : ''}`}>
        <h4>
          <i className="fa fa-bars mr-2 move"></i>
          {chapter.name}

          <div className="buttons">
            <button className="btn btn-xs btn-icon btn-themecolor mr-1" onClick={() => {setActiveChapter(chapter); setActiveLesson(null)}}><i className="fa fa-pencil"></i></button>
            <button className="btn btn-xs btn-icon btn-danger" onClick={() => deleteChapter(chapter)}><i className="fa fa-trash"></i></button>
          </div>
        </h4>

        {lessons && lessons.length ?
          <ReactSortable
            list={lessons}
            tag="ul"
            handle=".move"
            className="list-group lessons"
            setList={(newState) => sortLessons(chapter, newState)}
          >
            {lessons.map(lesson =>
              <li key={lesson.id} className={`list-group-item ${lesson.id == activeLesson?.id && 'active'}`}>
                <i className="fa fa-bars move mr-2"></i>
                {lesson.name}

                <div className="buttons">
                  <button className="btn btn-xs btn-icon btn-themecolor mr-1" onClick={() => {setActiveLesson(lesson); setActiveChapter(null)}}><i className="fa fa-pencil"></i></button>
                  <button className="btn btn-xs btn-icon btn-danger" onClick={() => deleteLesson(lesson)}><i className="fa fa-trash"></i></button>
                </div>
              </li>
            )}
          </ReactSortable> : null
        }

        <button className="btn btn-secondary btn-xs mt-2" onClick={() => addLesson(chapter)}>{I18n.t('add_lesson')}</button>
      </div>
    </div>
  )
}

export default Chapter