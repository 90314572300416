import { useState, useEffect, useMemo, useRef } from 'react'
import { useForm } from "react-hook-form"
import { useDropzone } from 'react-dropzone'
import ReactPlayer from 'react-player'
import { Editor } from '@tinymce/tinymce-react';
import Attachable from './attachable'

const ClubPostForm = ({ post, cancel_path }) => {
  const maxFileSize = 630000000
  const uuid = useMemo(() => Date.now(), [])

  const [files, setFiles] = useState([]);
  const [mp3Url, setMp3Url] = useState(post?.mp3?.url);
  const [videoUrl, setVideoUrl] = useState(post?.video?.url || post?.video_url || post.external_video);
  const { register, handleSubmit, setFocus, watch, formState: { errors }, setValue } = useForm({defaultValues: post})
  const watched = watch()
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps
  } = useDropzone({
    maxFiles: 1,
    accept: {
      'video/mp4': []
    },
    validator: sizeValidator,
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles);
    }
  });

  const editorRef = useRef()

  useEffect(() => {
    setFocus("name")
  }, [setFocus])

  useEffect(() => {
    if (files.length) {
      const file = files[0]
      const formData = new FormData()
      formData.append('video', file)
      formData.append("Content-Type", file.type);

      jQuery.ajax({
        url: `/api/club_posts/${post.id}/upload_video`,
        dataType: "xml",
        cache: false,
        method: 'POST',
        processData: false,
        contentType: false,
        data: formData,
        xhr: () => {
          var xhr = new window.XMLHttpRequest();
          xhr.upload.addEventListener("progress", function(evt) {
              if (evt.lengthComputable) {
                  var percentComplete = ((evt.loaded / evt.total) * 100);
                  $(".progress-bar").width(percentComplete + '%');
              }
          }, false);
          return xhr;
        },
        success: () => {
          jQuery.ajax({
            url: `/api/club_posts/${post.id}`,
            success: (response) => {
              setVideoUrl(response.club_post.video_url)
            }
          })
        },
        error: (data) => {
          console.log(data)
        }
      })
    }
  }, [files])

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  function sizeValidator(file) {
    if (file.size > maxFileSize) {
      return {
        code: "file-too-large",
        message: `File is larger ${maxFileSize}`
      };
    }

    return null
  }

  const changeDesc = (content) => {
    setValue('content', content)
  }

  const deleteVideo = () => {
    if (confirm(I18n.t('really_delete_video'))) {
      jQuery.ajax({
        url: `/api/club_posts/${post.id}/delete_video`,
        method: 'DELETE',
        success: () => {
          setFiles([])
          setVideoUrl(null)
          setValue('external_video', null)
        }
      })
    }
  }

  const deleteMp3 = () => {
    if (confirm(I18n.t('really_delete_mp3'))) {
      jQuery.ajax({
        url: `/api/club_posts/${post.id}/delete_mp3`,
        method: 'DELETE',
        success: () => {
          setMp3Url(null)
          setValue('mp3', null)
        }
      })
    }
  }

  const uploadMp3 = (e) => {
    if (!e.target.files[0]) return

    const formData = new FormData()
    formData.append('mp3', e.target.files[0])
    formData.append("Content-Type", e.target.files[0].type);

    jQuery.ajax({
      url: `/api/club_posts/${post.id}/upload_mp3`,
      dataType: "xml",
      cache: false,
      method: 'POST',
      processData: false,
      contentType: false,
      data: formData,
      success: () => {
        jQuery.ajax({
          url: `/api/club_posts/${post.id}`,
          success: (response) => {
            setMp3Url(response.club_post.audio_url)
          }
        })
      },
      error: (data) => {
        console.log(data)
      }
    })
  }

  const cancel = () => {
    window.location.href = cancel_path
  }

  const updatePost = (formData) => {
    $.ajax({
      url: `/api/club_posts/${post.id}`,
      method: 'PATCH',
      data: {
        club_post: _.pick(formData, ['name', 'content', 'active'])
      },
      success: (response) => {
        if (response.errors) {
          alert(response.errors.join('. '))
        } else {
          window.location.href = response.url
        }
      }
    })
  }

  return <>
    <div className="card">
      <div className="card-body">
        <form onSubmit={handleSubmit(updatePost)}>
          <div className={`form-group ${errors.name && 'field_with_errors'}`}>
            <label htmlFor="name">{I18n.t('activerecord.attributes.club_post.name')}</label>
            <input {...register('name', {required: true})} id="name" type="text" className="form-control" />
            {errors.name?.type === "required" && <label className="label label-danger">{I18n.t('simple_form.required.text')}</label>}
          </div>

          {videoUrl ?
            <div className="mb-2">
              <label className="radio_buttons">{I18n.t('activerecord.attributes.lesson.video')}</label>
              <ReactPlayer url={videoUrl + "?time=" + uuid} controls width={'100%'} />

              <div className="mt-2">
                <a href="#" className="btn btn-xs btn-danger" onClick={(e) => !e.preventDefault() && deleteVideo()}>{I18n.t('delete_video')}</a>
              </div>
            </div> :

            <>
              <div className="i-checks radio_buttons">
                <label className="radio_buttons">{I18n.t('activerecord.attributes.lesson.video')}</label>
                <span className="radio">
                  <input {...register('video_type')} className="radio_buttons form-control" type="radio" value="external" id="video_type1" />
                  <label className="collection_radio_buttons" htmlFor="video_type1">{I18n.t('video_types.external')}</label>
                </span>

                <span className="radio">
                  <input {...register('video_type')} className="radio_buttons form-control" type="radio" value="file" id="video_type2"/>
                  <label className="collection_radio_buttons" htmlFor="video_type2">{I18n.t('video_types.file')}</label>
                </span>
              </div>

              {watched?.video_type == 'file' ?
                <section className="mb-2">
                  <div className={`${files && files.length ? 'hide' : ''}`}>
                    <div {...getRootProps({ className: 'dropzone' })}>
                      <input {...getInputProps()} />
                      <p>{I18n.t('dragdrop')}</p>
                      <em>{I18n.t('dragdrop_help')}</em>
                    </div>
                  </div>

                  <aside>
                    {files && files.length ?
                      <>
                        {files.map(file => (
                          <div className="alert alert-info" key={file.path}>
                            <h4>{I18n.t('video_uploading')}</h4>
                            {file.path} - {Math.round(file.size / 1000 / 1000)} MB

                            <div className="progress">
                              <div className="progress-bar" style={{width: '0%'}}></div>
                            </div>
                          </div>
                        ))}
                      </> : null
                    }

                    {fileRejections && fileRejections.length ?
                      <>
                        <h4>Rejected files</h4>
                        {fileRejections.map(({ file, errors }) => (
                          <div className="alert alert-danger" key={file.path}>
                            <span className="label label-danger">{file.path} - {Math.round(file.size / 1000 / 1000)} MB </span>
                            <ul>
                              {errors.map(e => <li key={e.code}>{e.message}</li>)}
                            </ul>
                          </div>
                          )
                        )}
                      </> : null
                    }
                  </aside>
                </section> : null
              }

              {watched?.video_type == 'external' ?
                <div className={`form-group ${errors.external_video && 'field_with_errors'}`}>
                  <label htmlFor="external_video">{I18n.t('activerecord.attributes.lesson.external_video')}</label>
                  <input {...register('external_video')} id="external_video" type="text" className="form-control" placeholder="https://" />
                </div> : null
              }
            </>
          }

          <div>
            <div className={`form-group`}>
              <label htmlFor="mp3">{I18n.t('activerecord.attributes.lesson.mp3')}</label>
              {mp3Url ?
                <div>
                  <audio src={mp3Url} controls />
                  <br/>
                  <small><a href="#" className="text-danger" onClick={deleteMp3}>{I18n.t('delete')}</a></small>
                </div> :
                <>
                  <input onChange={uploadMp3} id="cert" type="file" className="form-control" />
                  <small>*.mp3</small>
                </>
              }
            </div>
          </div>

          <label>{I18n.t('activerecord.attributes.club_post.content')}</label>
          <Editor
            key={uuid}
            tinymceScriptSrc={'https://cdnjs.cloudflare.com/ajax/libs/tinymce/7.0.1/tinymce.min.js'}
            onInit={(evt, editor) => editorRef.current = editor}
            initialValue={post.content}
            onChange={(x) => changeDesc(x.level.content)}
            init={{
              height: 300,
              language: I18n.locale,
              language_url: `https://dogres.cz/tiny_langs/${I18n.locale}.js`,
              menubar: false,
              statusbar: false,
              setup: function (editor) {
                const openGallery = () => {
                  $.fancybox.open({
                    src: '/trainer/photos',
                    type: 'iframe'
                  })
                };
                editor.ui.registry.addButton("gallery", {
                  icon: 'image',
                  onAction: openGallery
                });
              },
              link_default_target: '_blank',
              relative_urls: false,
              convert_urls : false,
              link_assume_external_targets: true,
              plugins: [
                'advlist', 'autoresize', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                'insertdatetime', 'table', 'code', 'help', 'wordcount'
              ],
              toolbar: 'undo redo | blocks | ' +
                'link gallery bold italic forecolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'table code paste | removeformat',
              content_style: 'body { font-family:sans-serif; font-size:14px }'
            }}
          />

          <Attachable
            saveUrl={`/api/club_posts/${post.id}/upload_attachment`}
            attachmentsUrl={`/api/club_posts/${post.id}/attachments`}
          />

          <button type="submit" className="btn btn-themecolor">{I18n.t('save')}</button>
          <button className="btn btn-secondary ml-2" onClick={cancel}>{I18n.t('cancel')}</button>
        </form>
      </div>
    </div>
  </>
}

export default ClubPostForm