import { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'

const Attachable = ({saveUrl, attachmentsUrl}) => {
  const maxFileSize = 10000000
  const [files, setFiles] = useState([])
  const [attachments, setAttachments] = useState()
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps
  } = useDropzone({
    maxFiles: 10,
    accept: {
      'image/jpg': [],
      'image/png': [],
      'application/pdf': [],
      'application/zip': [],
      'application/doc': [],
      'application/docx': [],
      'application/xls': [],
      'application/xlsx': []
    },
    validator: sizeValidator,
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles);
    }
  });

  const fetchFiles = () => {
    jQuery.ajax({
      url: attachmentsUrl,
      success: (response) => {
        setAttachments(response.attachments)
      }
    })
  }

  const deleteAttachment = (id) => {
    if (confirm(I18n.t('really_delete_attachment'))) {
      jQuery.ajax({
        method: 'delete',
        url: '/api/attachments/' + id,
        success: (response) => {
          setAttachments(attachments.filter(a => a.id != id))
        }
      })
    }
  }

  function sizeValidator(file) {
    if (file.size > maxFileSize) {
      return {
        code: "file-too-large",
        message: `File is larger ${maxFileSize}`
      };
    }

    return null
  }

  useEffect(() => {
    fetchFiles()
  }, [])

  useEffect(() => {
    if (files.length) {
      files.map(file => {
        const formData = new FormData()
        formData.append('file', file)
        formData.append("Content-Type", file.type);

        jQuery.ajax({
          url: saveUrl,
          dataType: "xml",
          cache: false,
          method: 'POST',
          processData: false,
          contentType: false,
          data: formData,
          success: () => {
            fetchFiles()
          },
          error: (data) => {
            console.log(data)
          }
        })
      })
    }
  }, [files])

  return (
    <section className="mb-3 mt-3">
      <label>{I18n.t('attachments')}</label>

      <div>
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <p>{I18n.t('dragdrop-attachments')}</p>
          <em>{I18n.t('dragdrop_help_attachments')}</em>
        </div>
      </div>

      <aside className="mt-3">
        {attachments && attachments.length ?
          <>
            <ul className="list-group">
              {attachments.map(at => (
                <li className="list-group-item" key={at.id}>
                  <a href={at.attachment_url} target="_blank">{at.to_s}</a>
                  <small>
                    <a href='#' className="ml-3 text-danger" onClick={(e) => !e.preventDefault() && deleteAttachment(at.id)}>{I18n.t('delete')}</a>
                  </small>

                </li>
              ))}
            </ul>
          </> : null
        }

        {fileRejections && fileRejections.length ?
          <>
            <h4>Rejected files</h4>
            {fileRejections.map(({ file, errors }) => (
              <div className="alert alert-danger" key={file.path}>
                <span className="label label-danger">{file.path} - {Math.round(file.size / 1000 / 1000)} MB </span>
                <ul>
                  {errors.map(e => <li key={e.code}>{e.message}</li>)}
                </ul>
              </div>
              )
            )}
          </> : null
        }
      </aside>
    </section>
  )
}

export default Attachable