import { useMemo, useRef, useEffect } from 'react'
import { Editor } from '@tinymce/tinymce-react';

const MceEditor = ({ input_name, default_value }) => {
  const uuid = useMemo(() => Date.now(), [])
  const editorRef = useRef()

  return (
    <Editor
      key={uuid}
      initialValue={default_value}
      onInit={(evt, editor) => editorRef.current = editor}
      textareaName={input_name}
      tinymceScriptSrc={'https://cdnjs.cloudflare.com/ajax/libs/tinymce/7.0.1/tinymce.min.js'}
      init={{
        height: 300,
        language: I18n.locale,
        language_url: `https://dogres.cz/tiny_langs/${I18n.locale}.js`,
        menubar: false,
        statusbar: false,
        setup: function (editor) {
          const openGallery = () => {
            $.fancybox.open({
              src: '/trainer/photos',
              type: 'iframe'
            })
          };
          editor.ui.registry.addButton("gallery", {
            icon: 'image',
            onAction: openGallery
          });
        },
        plugins: [
          'advlist', 'autoresize', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
          'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
          'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
        ],
        link_default_target: '_blank',
        relative_urls: false,
        convert_urls : false,
        link_assume_external_targets: true,
        toolbar: 'undo redo | blocks | ' +
          'link gallery bold italic forecolor | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent | ' +
          'table code paste | removeformat',
        content_style: 'body { color: #67757c; font-family:sans-serif; font-size: 1rem } p { margin: 0;} .tox-promotion {display: none !important;}'
      }}
    />
  )
}

export default MceEditor