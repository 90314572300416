import { useEffect } from 'react'
import { useForm } from "react-hook-form"

const ChapterForm = ({chapter, cancel, updateChapter}) => {

  const { register, handleSubmit, setFocus, watch, formState: { errors } } = useForm({defaultValues: chapter})

  const watched = watch()

  useEffect(() => {
    setFocus("name");
  }, [setFocus]);

  return (
    <div className="card">
      <div className="card-header">
        <h4 className="mb-0"><strong>{I18n.t('chapter')}</strong> {'-'} {watched?.name}</h4>
      </div>
      <div className="card-body">
        <form onSubmit={handleSubmit(updateChapter)}>
          <div className={`form-group ${errors.name && 'field_with_errors'}`}>
            <label htmlFor="name">{I18n.t('activerecord.attributes.chapter.name')}</label>
            <input {...register('name', {required: true})} id="name" type="text" className="form-control" />
            {errors.name?.type === "required" && <label className="label label-danger">{I18n.t('simple_form.required.text')}</label>}
          </div>

          <button type="submit" className="btn btn-themecolor">{I18n.t('save')}</button>
          <button className="btn btn-secondary ml-2" onClick={cancel}>{I18n.t('cancel')}</button>
        </form>
      </div>
    </div>
  )
}

export default ChapterForm